type Options = { json?: boolean };

type PlainObject = Record<string, unknown>;

type ApiRequestInit = Omit<RequestInit, 'body'> & {
  body?: RequestInit['body'] | PlainObject;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
const isPlainObject = (value: any): value is PlainObject => value?.constructor === Object;

export const request = <ResponseType>(
  url: string | URL,
  init: ApiRequestInit,
  options: Options = {},
) => {
  const json = options.json || false;

  if (isPlainObject(init.body)) {
    init.body = JSON.stringify(init.body);
  }

  const csrfToken = document.querySelector<HTMLMetaElement>('meta[name="csrf-token"]')?.content;

  return fetch(url, {
    ...init,
    body: init.body,
    headers: {
      ...(json ? { 'Content-Type': 'application/json' } : {}),
      ...(csrfToken ? { 'X-CSRF-Token': csrfToken } : {}),
      ...init?.headers,
    },
    signal: init.signal || AbortSignal.timeout(30_000),
  }).then(async (response) => {
    if (!response.ok) {
      let errorMessage = `Request failed: ${response.status} ${response.statusText}`;

      try {
        const contentType = response.headers.get('content-type');

        if (contentType?.includes('application/json')) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const data = await response.json();

          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          if (data.errors?.length > 0) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
            errorMessage += ` - ${data.errors.join(', ')}`;
          }
        }
      } catch (error) {
        console.warn(error);
      }

      throw new Error(errorMessage);
    }

    return json ? response.json() : response;
  }) as Promise<ResponseType>;
};
